import { useState } from "react";
import PropTypes from "prop-types";
import { Route, Navigate, Routes, useNavigate } from "react-router-dom";
import { eventNames } from "utils/EventsTracking";
import requestToServer from "utils/requestToServer";
import useTranslation from "hooks/useTranslation";
import { withHashRouter } from "utils/withHashRouter";
import Summary from "components/payments/Summary";
import UnrecoverableError from "components/ErrorScreen/UnrecoverableError";
import RetailTransaction from "./screens/RetailTransaction";
import RetailTransactionSignature from "./screens/RetailTransactionSignature";
import ECommerceTransaction from "./screens/ECommerceTransaction";
import PaymentSuccessful from "./screens/PaymentSuccessful";

const STEP_SUMMARY_PATH = "/summary";
const STEP_RETAIL_PATH = "/swipe";
const STEP_RETAIL_SIGNATURE_PATH = "/signature";
const STEP_E_COMMERCE_PATH = "/form";
const STEP_SUCCESS_PATH = "/success";
const STEP_ERROR_PATH = "/error";

const UniversalPayment = ({
  previsitToken,
  transactionToken,
  retailTransactionSubmissionPath,
  eCommerceTransactionSubmissionPath,
  skipPath,
  totalOwed,
  fixedFee,
  selfPayFee,
  copay,
  outstandingBalance,
  collectionsBalance,
  claims,
  showOutstandingBalanceDetails,
  showSuccessPage,
  minimumPaymentAmount,
  paidAmount: initialPaidAmount,
  paymentUnrecoverable: initialPaymentUnrecoverable,
  paymentRequired,
  patient,
  paymentCashTextLabel,
  inOfficeCashPaymentEnabled,
  stateOptions,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const t = useTranslation("universal.universal_payment");
  const navigate = useNavigate();
  const [amountToPay, setAmountToPay] = useState(totalOwed);
  const [paidAmount, setPaidAmount] = useState(initialPaidAmount);
  const [paymentUnrecoverable, setPaymentUnrecoverable] = useState(initialPaymentUnrecoverable);
  const [nextStepPath, setNextStepPath] = useState(window.location.href);
  const retailPaymentAllowed = !!retailTransactionSubmissionPath;
  const paymentSuccessful = !!paidAmount;

  const handleSkip = () => {
    setSubmitting(true);
    requestToServer({ path: skipPath, method: "POST" });
  };

  const handleECommerceSuccess = (response) => {
    setPaidAmount(amountToPay);

    if (showSuccessPage) {
      setNextStepPath(response.next_step_path);
      navigate(STEP_SUCCESS_PATH, { replace: true });
    } else {
      window.location.href = response.next_step_path;
    }
  };

  const handleContinueOnSuccess = () => {
    window.location.href = nextStepPath;
  };

  const handleFailure = () => {
    setPaymentUnrecoverable(true);
    navigate(STEP_ERROR_PATH, { replace: true });
  };

  const handlePayWithCard = () => {
    navigate(retailPaymentAllowed ? STEP_RETAIL_PATH : STEP_E_COMMERCE_PATH);
  };

  return (
    <Routes>
      <Route
        element={(
          <Summary
            amountToPay={amountToPay}
            claims={claims}
            collectionsBalance={collectionsBalance}
            copay={copay}
            fixedFee={fixedFee}
            inOfficeCashPaymentEnabled={inOfficeCashPaymentEnabled}
            isSubmitting={isSubmitting}
            minimumPaymentAmount={minimumPaymentAmount}
            onAmountToPayChange={setAmountToPay}
            onPayWithCard={handlePayWithCard}
            onPaymentSkip={handleSkip}
            outstandingBalance={outstandingBalance}
            payWithCashLaterAllowed={retailPaymentAllowed}
            paymentCashTextLabel={paymentCashTextLabel}
            paymentRequired={paymentRequired}
            paymentSuccessful={paymentSuccessful}
            paymentUnrecoverable={paymentUnrecoverable}
            selfPayFee={selfPayFee}
            showOutstandingBalanceDetails={showOutstandingBalanceDetails}
            totalOwed={totalOwed}
          />
        )}
        path={STEP_SUMMARY_PATH}
      />

      <Route
        element={(
          <RetailTransaction
            amountToPay={amountToPay}
            onSuccessfulPayment={() => navigate(STEP_RETAIL_SIGNATURE_PATH, { replace: true })}
            onSwipeNotDetected={() => navigate(STEP_E_COMMERCE_PATH)}
            onUnrecoverableError={handleFailure}
            submissionPath={retailTransactionSubmissionPath}
            swipeDetectedEventName={eventNames.PV_SWIPE_DETECTED}
            swipeNotDetectedEventName={eventNames.PV_SWIPE_NOT_DETECTED}
          />
        )}
        path={STEP_RETAIL_PATH}
      />

      <Route
        element={(
          <ECommerceTransaction
            amountToPay={amountToPay}
            onSuccess={handleECommerceSuccess}
            onUnrecoverableError={handleFailure}
            patient={patient}
            previsitToken={previsitToken}
            stateOptions={stateOptions}
            submissionPath={eCommerceTransactionSubmissionPath}
            transactionToken={transactionToken}
          />
        )}
        path={STEP_E_COMMERCE_PATH}
      />

      <Route
        element={(
          <RetailTransactionSignature
            amountToPay={amountToPay}
            submissionPath={retailTransactionSubmissionPath}
          />
        )}
        path={STEP_RETAIL_SIGNATURE_PATH}
      />

      <Route
        element={(
          <PaymentSuccessful
            onContinue={handleContinueOnSuccess}
            paidAmount={paymentSuccessful ? paidAmount : amountToPay}
          />
        )}
        path={STEP_SUCCESS_PATH}
      />

      <Route
        element={(
          <UnrecoverableError
            description={t("error_description")}
            onActionClick={handleSkip}
            title={t("error_title")}
          />
        )}
        path={STEP_ERROR_PATH}
      />
      <Route element={<Navigate replace to={STEP_SUMMARY_PATH} />} path="*" />
    </Routes>
  );
};

export default withHashRouter(UniversalPayment);

UniversalPayment.propTypes = {
  previsitToken: PropTypes.string,
  transactionToken: PropTypes.string.isRequired,
  retailTransactionSubmissionPath: PropTypes.string,
  eCommerceTransactionSubmissionPath: PropTypes.string.isRequired,
  skipPath: PropTypes.string.isRequired,
  totalOwed: PropTypes.number.isRequired,
  fixedFee: PropTypes.bool.isRequired,
  selfPayFee: PropTypes.number,
  copay: PropTypes.number,
  outstandingBalance: PropTypes.number,
  collectionsBalance: PropTypes.number,
  minimumPaymentAmount: PropTypes.number.isRequired,
  paidAmount: PropTypes.number.isRequired,
  paymentUnrecoverable: PropTypes.bool.isRequired,
  paymentRequired: PropTypes.bool.isRequired,
  paymentCashTextLabel: PropTypes.string,
  inOfficeCashPaymentEnabled: PropTypes.bool,
  claims: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      clean: PropTypes.bool.isRequired,
      date: PropTypes.string.isRequired,
      balance: PropTypes.number.isRequired,
      charges: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          date: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          balance: PropTypes.number.isRequired,
        }),
      ).isRequired,
    }),
  ),
  showOutstandingBalanceDetails: PropTypes.bool,
  showSuccessPage: PropTypes.bool,
  patient: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
  }).isRequired,
  stateOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
};

UniversalPayment.defaultProps = {
  claims: [],
  collectionsBalance: null,
  copay: null,
  inOfficeCashPaymentEnabled: false,
  outstandingBalance: null,
  paymentCashTextLabel: null,
  previsitToken: null,
  retailTransactionSubmissionPath: null,
  selfPayFee: null,
  showOutstandingBalanceDetails: false,
  showSuccessPage: false,
};
